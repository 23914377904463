import { type VariantProps, cva } from "class-variance-authority";

export const buttonVariants = cva(
  cn(
    "inline-flex gap-3 items-center justify-center whitespace-nowrap shrink-0 rounded-md transition-colors",
    "disabled:pointer-events-none disabled:opacity-50 cursor-pointer"
  ),
  {
    variants: {
      variant: {
        default: "text-color-5 border hover:bg-color-2 hover:text-color-6",
        link: "hover:text-online underline-offset-4 underline decoration-color-3 hover:decoration-online !px-0 !h-auto",
        black: "bg-color-8 !text-color-1 hover:bg-color-7",
        white: "text-color-5 hover:text-color-7 hover:bg-color-3/50 dark:hover:bg-color-3",
        success: "bg-success hover:bg-success-hover text-color-1",
        online: "bg-online hover:bg-online-hover text-color-1",
        error: "bg-error hover:bg-error-hover text-color-1",
        warning: "bg-warning hover:bg-warning-hover text-color-1",
        rainbow: "rainbow rounded-lg before:rounded-lg after:rounded-lg"
      },
      size: {
        xs: "h-5 min-w-5 rounded-md tracking-wide",
        sm: "h-8 min-w-8 rounded-md [&.has-text]:px-3 text-xs [&>svg]:size-5 tracking-wide",
        default: "h-10 min-w-10 [&.has-text]:px-4 text-base [&>svg]:size-5",
        lg: "h-11 min-w-11 rounded-md [&.has-text]:px-8 text-lg [&>svg]:size-6"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
