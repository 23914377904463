import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import forum_45global from "/src/src/middleware/forum.global.ts";
import save_45global from "/src/src/middleware/save.global.ts";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  forum_45global,
  save_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "ory-auth": () => import("/src/src/middleware/ory-auth.ts")
}