import { defineAsyncComponent } from 'vue'
export default {
  activity: defineAsyncComponent(() => import("/src/src/layouts/activity.vue").then(m => m.default || m)),
  claim: defineAsyncComponent(() => import("/src/src/layouts/claim.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/src/src/layouts/default.vue").then(m => m.default || m)),
  empty: defineAsyncComponent(() => import("/src/src/layouts/empty.vue").then(m => m.default || m)),
  form: defineAsyncComponent(() => import("/src/src/layouts/form.vue").then(m => m.default || m)),
  forum: defineAsyncComponent(() => import("/src/src/layouts/forum.vue").then(m => m.default || m)),
  handbook: defineAsyncComponent(() => import("/src/src/layouts/handbook.vue").then(m => m.default || m)),
  page: defineAsyncComponent(() => import("/src/src/layouts/page.vue").then(m => m.default || m))
}