import { type MatrixClient, SyncState, ClientEvent, type RoomMember } from "matrix-js-sdk";

async function waitForNextSync(matrixClient: MatrixClient) {
  return new Promise((resolve) => {
    const syncListener = (state: SyncState) => {
      if (state === SyncState.Syncing) {
        matrixClient.removeListener(ClientEvent.Sync, syncListener);
        resolve(true);
      }
    };
    setTimeout(() => {
      matrixClient.removeListener(ClientEvent.Sync, syncListener);
      resolve(true);
    }, 10000);

    matrixClient.on(ClientEvent.Sync, syncListener);
  });
}
function roomAliasToRoomName(alias: string) {
  return alias.split(":")[0].replaceAll("#", "");
}
function channelMemberToParticipant(member: RoomMember) {
  return {
    id: member.name,
    username: member.name.split(":")[0] ?? ""
  };
}

export function nameToChannel(name: string | undefined, chatBaseUrl: string) {
  if (!name) return "";
  // Skip transformation if name matches date format (YYYY-MM-DD-XXXXX)
  if (/^\d{4}-\d{2}-\d{2}-\d+$/.test(name)) {
    return `#${name}:${chatBaseUrl.split("://")[1]}`;
  }
  return `#ff-channel-${name}:${chatBaseUrl.split("://")[1]}`;
}

export async function switchToRoom(name: string) {
  const store = useChatStore();
  const config = useRuntimeConfig();
  const chatStore = useChatStore();
  const { $chat } = useNuxtApp();

  chatStore.reset();

  if (!store.matrixIsReady) {
    const retryCount = 5;
    console.warn("Matrix chat not initialized yet, retrying...");
    for (let i = 0; i < retryCount; i++) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (store.matrixIsReady) break;
      console.warn(`Retry attempt ${i + 1}/${retryCount}`);
      if (i === retryCount - 1) {
        console.error("Matrix chat could not be initialized.");
        return;
      }
    }
  }
  const { $toast } = useNuxtApp();

  store.roomAlias = nameToChannel(name, config.public.chatBaseUrl);
  store.roomName = name;
  store.roomIsReady = false;
  store.threadIsReady = false;
  store.chats[store.roomName] = { scrollTop: 0, showThirdPanel: false, threadId: undefined, isTyping: [] };

  try {
    store.commentsMap.clear();
    store.eventsMap.clear();

    // Check if room is already joined if not join the room
    const { room_id: roomId } = await withRetry(
      () => $chat.client.getRoomIdForAlias(store.roomAlias),
      5,
      300
    );

    if (!roomId) {
      $toast.error("Room not found", {
        description: `Room with alias ${store.roomAlias} not found`
      });
      return;
    }
    let room = $chat.client.getRoom(roomId!);

    if (!room) {
      await withRetry(() => $chat.client.joinRoom(roomId!, { syncRoom: false }), 10, 300);
      console.log("waiting for next sync");
      await waitForNextSync($chat.client as MatrixClient);
      room = $chat.client.getRoom(roomId!);
    }
    if (!room) {
      throw new Error(`Failed to join Room ${roomAliasToRoomName(store.roomAlias)}`);
    }

    const timeline = room.getLiveTimeline();
    const events = timeline.getEvents();

    await $chat.client.paginateEventTimeline(timeline, {
      backwards: true,
      limit: store.eventPageSize
    });

    store.eventsMap = new Map(events.map((event) => [event.getId()!, event]));
    events.forEach((event) => store.handleRoomEvent(event));

    store.participants = room
      .getMembers()
      .filter((member) => !member.name.includes("system"))
      .map(channelMemberToParticipant);
    store.currentRoom = room;

    const threadId = chatStore.getThreadId;
    if (threadId) {
      // after loading content update thread contents
      store.switchToThread(threadId);
    }
    store.roomIsReady = true;
  } catch (joinError) {
    store.isLoadingPage = true;
    store.isLoadingRoom = true;
    store.error = joinError as string;
    $toast.error("Error during joining Room", {
      description: joinError as string
    });
    return;
  }

  store.isLoadingPage = false;
  store.isLoadingRoom = false;
  store.error = null;
}
