subscription subscribeEvents($id: bigint = 0, $batchSize: Int = 10) {
  eventStream(batchSize: $batchSize, cursor: { initialValue: { id: $id } }) {
    id
    claimId
    action
    tableName
    entryId
    claim {
      id
      status
      shortId
      processId
    }
    user {
      id
      username
    }
    content
    metadata
    createdAt
  }
}
