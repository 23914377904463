import type { MatrixEvent } from "matrix-js-sdk";

import type { Comment } from "~/types";

export function addEvent(event: MatrixEvent) {
  const store = useChatStore();
  const eventId = event.getId();
  if (!eventId) {
    console.warn("useEventMap: Event has no Id and will not be added to the map", { event });
    return;
  }
  store.eventsMap.set(eventId, event);
}

export function updateEventId(newEventId: string, oldEventId: string) {
  const store = useChatStore();
  // console.warn("useEventMap: updateEventId", toPlainClone({ newEventId, oldEventId }));
  const event = store.eventsMap.get(oldEventId);
  if (event) {
    store.eventsMap.set(newEventId, event);
    store.eventsMap.delete(oldEventId);

    // check if event is and reaction event
    if (event.getType() === "m.reaction") {
      console.warn("useEventMap: updateEventId found reaction event", toPlainClone(event.event));
      const commentId = event.event.content!["m.relates_to"]?.event_id;
      const comment = store.getComment(commentId!);
      // find Reactions in comments
      console.warn("useEventMap: updateEventId found reaction in comment", toPlainClone(comment));
      const reaction = comment?.userReactions?.find((reaction) => reaction.id === oldEventId);
      if (comment && reaction) {
        console.warn("useEventMap: updateEventId found reaction in comment", toPlainClone(reaction));
        reaction.id = newEventId;
        reaction.pending = false;
        store.setComment(comment);
      } else {
        console.warn(
          `useEventMap: Reaction with id ${oldEventId} not found in comment`,
          toPlainClone(comment)
        );
      }
      return;
    }

    // check if it is an comment Event
    if (event.getType() === "m.room.message") {
      const comment = store.commentsMap.get(oldEventId);
      console.warn("useEventMap: updateEventId found comment event", { event, comment });
      if (comment) {
        comment.id = newEventId;
        store.commentsMap.set(newEventId, comment);
        store.commentsMap.delete(oldEventId);
        return;
      }
    }
  }
}

export function setComment(comment: Comment) {
  const store = useChatStore();
  store.commentsMap.set(comment.id, comment);
}

export function reset() {
  const store = useChatStore();

  store.roomIsReady = false;
  store.threadIsReady = false;

  store.isLoadingRoom = false;
  store.isLoadingPage = false;
  store.participants = [];
  store.currentRoom = null;
  store.roomAlias = "";
  store.error = null;

  store.sendIsTypingPending = false;
  store.commentsMap.clear();
  store.eventsMap.clear();
}
