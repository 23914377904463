export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    deleteAccount: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteAccount($userId: uuid = "") {
  deleteAccount(userId: $userId) {
    success
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAccount', 'query'),
    getUserAccountView: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserAccountView {
  userAccountView {
    bio
    email
    createdAt
    firstName
    id
    lastName
    mobileNumber
    profileImage
    pronouns
    updatedAt
    username
    signedCodeOfConduct
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserAccountView', 'query'),
    getCategories: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCategories {
  categories: category {
    name
    labelEn
    labelDe
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCategories', 'query'),
    getCategoriesPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCategoriesPaginated($offset: Int!, $limit: Int!) {
  category(offset: $offset, limit: $limit, orderBy: { name: ASC }) {
    name
    labelEn
    labelDe
    claimCategoriesAggregate {
      aggregate {
        count
      }
    }
  }
  categoryAggregate {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCategoriesPaginated', 'query'),
    deleteCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteCategory($name: String!) {
  deleteCategory(where: { name: { _eq: $name } }) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCategory', 'query'),
    insertCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertCategory($name: String!, $labelEn: String!, $labelDe: String!) {
  insertCategory(objects: [{ name: $name, labelEn: $labelEn, labelDe: $labelDe }]) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertCategory', 'query'),
    insertClaimCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertClaimCategory($claimId: uuid!, $categoryName: String!) {
  insertClaimCategoryOne(object: { categoryName: $categoryName, claimId: $claimId }) {
    id
    categoryName
    claimId
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertClaimCategory', 'query'),
    deleteClaimCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteClaimCategory($claimId: uuid!, $categoryName: String!) {
  data: updateClaimCategory(
    where: { _and: { categoryName: { _eq: $categoryName }, claimId: { _eq: $claimId } } }
    _set: { deleted: true }
  ) {
    returning {
      id
      categoryName
      claimId
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClaimCategory', 'query'),
    getChannels: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getChannels {
  data: channel(orderBy: { name: ASC }) {
    descriptionDe
    descriptionEn
    archived
    fileId
    internal
    labelEn
    labelDe
    name
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChannels', 'query'),
    getFirstChannel: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getFirstChannel {
  data: channel(orderBy: { name: ASC }, limit: 1) {
    name
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFirstChannel', 'query'),
    AskCheckbot: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation AskCheckbot($text: String = "Wer") {
  sendMessageToCheckbotMessagePost(textPayloadInput: { text: $text }) {
    type
    data {
      claim
      fileType
      link
      label
      summary
      url
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AskCheckbot', 'query'),
    deleteClaimById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteClaimById($id: uuid = "") {
  deleteClaimByPk(id: $id) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClaimById', 'query'),
    deleteOriginById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteOriginById($id: uuid = "") {
  data: updateOriginByPk(pkColumns: { id: $id }, _set: { deleted: true }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteOriginById', 'query'),
    filterClaims: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query filterClaims(
  $limit: Int = 10
  $offset: Int = 0
  $search: String = "%%"
  $claimStatus: [claim_status!] = ["accepted"]
  $filter: [ClaimBoolExp!] = { _and: [] }
) {
  data: claim(
    limit: $limit
    offset: $offset
    where: {
      _and: [
        { _or: [{ submitterNotes: { _ilike: $search } }, { synopsis: { _ilike: $search } }] }
        { status: { _in: $claimStatus } }
        { _and: $filter }
      ]
    }
    orderBy: { createdAt: DESC }
  ) {
    id
    shortId
    submitterNotes
    checkworthiness {
      category
      confidence
    }
    status
    synopsis
    processId
    internal
    ratingLabelName
    publishingUrl
    origins(limit: 1) {
      id
      url
      file {
        id
        mimeType
        name
      }
      index
    }
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
    userClaimStatuses {
      hasMarked
      hasRead
      lastViewedAt
    }
    createdAt
    createdByUser {
      id
      username
    }
    lastUpdate: claim_context_last_update {
      date: updatedAt
      user {
        id
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filterClaims', 'query'),
    getClaimById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getClaimById($id: uuid!) {
  claim: claimByPk(id: $id) {
    id
    status
    submitterNotes
    shortId
    processId
    synopsis
    ratingLabelName
    ratingStatement
    ratingSummary
    createdAt
    internal
    publishingUrl
    checkworthiness {
      category
      confidence
    }
    createdByUser {
      id
      username
    }
    updatedAt
    updatedByUser {
      id
      username
    }
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
    origins(orderBy: { index: ASC }) {
      id
      claimId
      index
      excerpt
      archiveUrl
      url
      remarks
      updatedAt
      updatedByUser {
        username
        id
      }
      createdAt
      createdByUser {
        username
        id
      }
      file {
        mimeType
        id
        transcription
        name
      }
    }
    facts(orderBy: { index: ASC }) {
      id
      index
      text
      createdAt
      createdByUser {
        username
        id
      }
      updatedAt
      updatedByUser {
        username
        id
      }
      sources(orderBy: { index: ASC }) {
        id
        index
        excerpt
        archiveUrl
        url
        remarks
        createdAt
        createdByUser {
          username
          id
        }
        updatedAt
        updatedByUser {
          username
          id
        }
        file {
          mimeType
          id
          transcription
          name
        }
      }
    }
    userClaimStatuses {
      hasMarked
      hasRead
      lastViewedAt
    }
    lastUpdate: claim_context_last_update {
      date: updatedAt
      user {
        id
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClaimById', 'query'),
    insertClaimOne: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertClaimOne {
  insertClaimOne(object: {}) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertClaimOne', 'query'),
    insertClaimOrigin: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertClaimOrigin(
  $claimId: uuid = ""
  $url: String = null
  $fileId: uuid = null
  $archiveUrl: String = null
  $excerpt: String = null
  $remarks: String = null
) {
  data: insertOriginOne(
    object: {
      archiveUrl: $archiveUrl
      excerpt: $excerpt
      fileId: $fileId
      url: $url
      claimId: $claimId
      remarks: $remarks
    }
  ) {
    id
    claimId
    index
    url
    archiveUrl
    excerpt
    remarks
    file {
      id
      mimeType
      name
      transcription
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertClaimOrigin', 'query'),
    updateClaimInternal: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimInternal($id: uuid = "", $internal: Boolean!) {
  updateClaimByPk(pkColumns: { id: $id }, _set: { internal: $internal }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimInternal', 'query'),
    updateOriginUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginUrl($id: uuid!, $url: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { url: $url }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginUrl', 'query'),
    updateOriginArchiveUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginArchiveUrl($id: uuid!, $archiveUrl: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { archiveUrl: $archiveUrl }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginArchiveUrl', 'query'),
    updateOriginExcerpt: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginExcerpt($id: uuid!, $excerpt: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { excerpt: $excerpt }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginExcerpt', 'query'),
    updateOriginRemarks: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginRemarks($id: uuid!, $remarks: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { remarks: $remarks }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginRemarks', 'query'),
    updateSourceFileIdToNull: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceFileIdToNull($id: uuid!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { fileId: null }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceFileIdToNull', 'query'),
    updateClaimPublishedUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimPublishedUrl($id: uuid!, $publishingUrl: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { publishingUrl: $publishingUrl }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimPublishedUrl', 'query'),
    updateClaimRatingLabelName: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingLabelName($id: uuid = "", $ratingLabelName: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingLabelName: $ratingLabelName }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingLabelName', 'query'),
    updateClaimRatingStatement: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingStatement($id: uuid = "", $ratingStatement: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingStatement: $ratingStatement }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingStatement', 'query'),
    updateClaimRatingSummary: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingSummary($id: uuid = "", $ratingSummary: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingSummary: $ratingSummary }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingSummary', 'query'),
    updateClaimStatus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimStatus($id: uuid!, $status: claim_status!) {
  updateClaimByPk(pkColumns: { id: $id }, _set: { status: $status }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimStatus', 'query'),
    updateClaimSubmitterNotes: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimSubmitterNotes($id: uuid = "", $submitterNotes: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { submitterNotes: $submitterNotes }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimSubmitterNotes', 'query'),
    updateClaimSynopsis: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimSynopsis($id: uuid = "", $synopsis: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { synopsis: $synopsis }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    publishingUrl
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimSynopsis', 'query'),
    getAllFilteredEventsPage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getAllFilteredEventsPage($pageSize: Int = 20, $idComparison: BigintComparisonExp = {}) {
  data: event(limit: $pageSize, orderBy: { id: DESC }, where: { _and: { id: $idComparison } }) {
    id
    claimId
    action
    tableName
    entryId
    claim {
      id
      status
      shortId
      processId
    }
    user {
      id
      username
    }
    content
    metadata
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllFilteredEventsPage', 'query'),
    getClaimFilteredEventsPage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getClaimFilteredEventsPage(
  $pageSize: Int = 20
  $claimComparison: ClaimBoolExp = {}
  $idComparison: BigintComparisonExp = {}
) {
  data: event(
    limit: $pageSize
    orderBy: { id: DESC }
    where: { _and: { id: $idComparison, claim: $claimComparison } }
  ) {
    id
    claimId
    action
    tableName
    entryId
    claim {
      id
      status
      shortId
      processId
    }
    user {
      id
      username
    }
    content
    metadata
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClaimFilteredEventsPage', 'query'),
    getLastEventId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getLastEventId($limit: Int = 10) {
  event(orderBy: { id: DESC }, limit: $limit) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLastEventId', 'query'),
    subscribeEvents: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`subscription subscribeEvents($id: bigint = 0, $batchSize: Int = 10) {
  eventStream(batchSize: $batchSize, cursor: { initialValue: { id: $id } }) {
    id
    claimId
    action
    tableName
    entryId
    claim {
      id
      status
      shortId
      processId
    }
    user {
      id
      username
    }
    content
    metadata
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'subscribeEvents', 'query'),
    deleteFactById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteFactById($id: uuid!) {
  data: updateFactByPk(pkColumns: { id: $id }, _set: { deleted: true }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteFactById', 'query'),
    insertFact: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertFact($claimId: uuid!, $text: String = null) {
  data: insertFactOne(object: { claimId: $claimId, text: $text }) {
    id
    index
    text
    claimId
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertFact', 'query'),
    updateFactTextById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateFactTextById($id: uuid!, $text: String!) {
  updateFactByPk(pkColumns: { id: $id }, _set: { text: $text }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateFactTextById', 'query'),
    GetHandbookSections: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetHandbookSections {
  handbookSections(orderBy: { titleDe: ASC }) {
    id
    titleDe
    titleEn
    teaserImage
    teaserTextDe
    teaserTextEn
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHandbookSections', 'query'),
    GetHandbookSectionById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetHandbookSectionById($id: uuid!) {
  handbookSections(where: { id: { _eq: $id } }) {
    contentDe
    contentEn
    titleDe
    titleEn
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHandbookSectionById', 'query'),
    UpdateHandbookSectionTeaser: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation UpdateHandbookSectionTeaser(
  $id: uuid!
  $titleDe: String
  $titleEn: String
  $teaserTextDe: String
  $teaserTextEn: String
) {
  updateHandbookSections(
    where: { id: { _eq: $id } }
    _set: { teaserTextDe: $teaserTextDe, teaserTextEn: $teaserTextEn, titleDe: $titleDe, titleEn: $titleEn }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateHandbookSectionTeaser', 'query'),
    UpdateHandbookSectionContent: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation UpdateHandbookSectionContent($id: uuid!, $contentDe: String, $contentEn: String) {
  updateHandbookSections(
    where: { id: { _eq: $id } }
    _set: { contentDe: $contentDe, contentEn: $contentEn }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateHandbookSectionContent', 'query'),
    InsertHandbookSection: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation InsertHandbookSection(
  $titleDe: String!
  $titleEn: String!
  $teaserTextDe: String!
  $teaserTextEn: String!
  $contentDe: String!
  $contentEn: String!
) {
  insertHandbookSections(
    objects: {
      contentDe: $contentDe
      contentEn: $contentEn
      teaserTextDe: $teaserTextDe
      teaserTextEn: $teaserTextEn
      titleDe: $titleDe
      titleEn: $titleEn
    }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertHandbookSection', 'query'),
    DeleteHandbookSection: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation DeleteHandbookSection($id: uuid!) {
  deleteHandbookSections(where: { id: { _eq: $id } }) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteHandbookSection', 'query'),
    mutateBlockRoomMessage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation mutateBlockRoomMessage($messageId: String = "", $roomId: String = "") {
  blockRoomMessage(messageId: $messageId, roomId: $roomId) {
    success
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mutateBlockRoomMessage', 'query'),
    GetPageContentByPageName: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetPageContentByPageName($pageName: String!) {
  pageContent(where: { pageName: { _eq: $pageName } }) {
    contentDe
    contentEn
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPageContentByPageName', 'query'),
    UpdatePageContent: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation UpdatePageContent($pageName: String!, $contentDe: String, $contentEn: String) {
  updatePageContent(
    where: { pageName: { _eq: $pageName } }
    _set: { contentDe: $contentDe, contentEn: $contentEn }
  ) {
    returning {
      pageName
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePageContent', 'query'),
    getRatingLabels: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getRatingLabels {
  ratingLabels: ratingLabel {
    labelDe
    labelEn
    name
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRatingLabels', 'query'),
    getRatingLabelsPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getRatingLabelsPaginated($offset: Int!, $limit: Int!) {
  ratingLabel(offset: $offset, limit: $limit, orderBy: { name: ASC }) {
    labelDe
    labelEn
    name
    claimsAggregate {
      aggregate {
        count
      }
    }
  }
  ratingLabelAggregate {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRatingLabelsPaginated', 'query'),
    deleteRatingLabel: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteRatingLabel($name: String!) {
  deleteRatingLabel(where: { name: { _eq: $name } }) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteRatingLabel', 'query'),
    insertRatingLabel: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertRatingLabel($labelDe: String!, $labelEn: String!, $name: String!) {
  insertRatingLabel(objects: [{ labelDe: $labelDe, labelEn: $labelEn, name: $name }]) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertRatingLabel', 'query'),
    insertSourceByFactId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertSourceByFactId(
  $factId: uuid!
  $fileId: uuid = null
  $url: String = null
  $excerpt: String = null
  $archiveUrl: String = null
  $remarks: String = null
) {
  insertSourceOne(
    object: {
      factId: $factId
      fileId: $fileId
      url: $url
      excerpt: $excerpt
      archiveUrl: $archiveUrl
      remarks: $remarks
    }
  ) {
    id
    index
    factId
    url
    archiveUrl
    excerpt
    remarks
    file {
      id
      mimeType
      name
      transcription
    }
    fact {
      claimId
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertSourceByFactId', 'query'),
    deleteSourceById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteSourceById($id: uuid!) {
  data: updateSourceByPk(pkColumns: { id: $id }, _set: { deleted: true }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteSourceById', 'query'),
    updateSourceUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceUrl($id: uuid!, $url: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { url: $url }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceUrl', 'query'),
    updateSourceArchiveUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceArchiveUrl($id: uuid!, $archiveUrl: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { archiveUrl: $archiveUrl }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceArchiveUrl', 'query'),
    updateSourceExcerpt: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceExcerpt($id: uuid!, $excerpt: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { excerpt: $excerpt }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceExcerpt', 'query'),
    updateSourceRemarks: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceRemarks($id: uuid!, $remarks: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { remarks: $remarks }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceRemarks', 'query'),
    updateOriginFileIdToNull: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginFileIdToNull($id: uuid!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { fileId: null }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginFileIdToNull', 'query'),
    getUserById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserById($id: uuid!) {
  userByPk(id: $id) {
    bio
    createdAt
    id
    profileImage
    pronouns
    updatedAt
    username
    deleted
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserById', 'query'),
    getUserByName: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserByName($name: String!) {
  user(where: { username: { _eq: $name } }) {
    bio
    createdAt
    id
    profileImage
    pronouns
    updatedAt
    username
    deleted
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserByName', 'query'),
    getUserIdByName: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserIdByName($name: String!) {
  user(where: { username: { _eq: $name } }) {
    id
    deleted
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserIdByName', 'query'),
    getUserAccountDetails: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserAccountDetails($ids: [uuid]!) {
  data: accountDetails(ids: $ids) {
    id
    role
    verified
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserAccountDetails', 'query'),
    getUserRole: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserRole($ids: [uuid]!) {
  data: accountDetails(ids: $ids) {
    role
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserRole', 'query'),
    getUsers: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUsers {
  user {
    email
    createdAt
    firstName
    lastName
    mobileNumber
    profileImage
    pronouns
    updatedAt
    username
    deleted
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUsers', 'query'),
    getUsersPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUsersPaginated(
  $offset: Int!
  $limit: Int!
  $sort: [UserOrderBy!]
  $username: String
  $email: String
) {
  user(
    offset: $offset
    limit: $limit
    orderBy: $sort
    where: { _or: [{ username: { _ilike: $username } }, { email: { _ilike: $email } }] }
  ) {
    id
    email
    username
    createdAt
    deleted
  }
  userAggregate(where: { _or: [{ username: { _ilike: $username } }, { email: { _ilike: $email } }] }) {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUsersPaginated', 'query'),
    requestVerificationCode: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation requestVerificationCode($email: String!) {
  data: requestVerificationCode(email: $email) {
    success
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestVerificationCode', 'query'),
    updateUserInfo: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserInfo(
  $id: uuid!
  $bio: String
  $firstName: String!
  $lastName: String!
  $pronouns: String
) {
  updateUserByPk(
    pkColumns: { id: $id }
    _set: { firstName: $firstName, lastName: $lastName, pronouns: $pronouns, bio: $bio }
  ) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserInfo', 'query'),
    updateUserRole: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserRole($userId: uuid!, $role: UserRole!) {
    updateUserRole(userId: $userId, role: $role) {
        id
        role
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserRole', 'query'),
    updateUserSignedCodeOfConduct: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserSignedCodeOfConduct($id: uuid!, $signedCodeOfConduct: Boolean!) {
  updateUserByPk(pkColumns: { id: $id }, _set: { signedCodeOfConduct: $signedCodeOfConduct }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserSignedCodeOfConduct', 'query'),
    verifyUserAccount: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation verifyUserAccount($userId: uuid!) {
  data: verifiyUserAccount(userId: $userId) {
    success
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'verifyUserAccount', 'query'),
    insertUserClaimStatusOne: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertUserClaimStatusOne($claimId: uuid!, $hasRead: Boolean!, $hasMarked: Boolean!) {
  insertUserClaimStatusOne(
    object: { claimId: $claimId, hasRead: $hasRead, hasMarked: $hasMarked, lastViewedAt: "now()" }
  ) {
    hasRead
    hasMarked
    lastViewedAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertUserClaimStatusOne', 'query'),
    insertUserClaimStatusOneWithoutLastViewedAt: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertUserClaimStatusOneWithoutLastViewedAt(
  $claimId: uuid!
  $hasRead: Boolean!
  $hasMarked: Boolean!
) {
  insertUserClaimStatusOne(object: { claimId: $claimId, hasRead: $hasRead, hasMarked: $hasMarked }) {
    hasRead
    hasMarked
    lastViewedAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertUserClaimStatusOneWithoutLastViewedAt', 'query'),
    updateUserClaimStatusByPkHasRead: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserClaimStatusByPkHasRead($userId: uuid!, $claimId: uuid!, $hasRead: Boolean!) {
  updateUserClaimStatusByPk(
    pkColumns: { claimId: $claimId, userId: $userId }
    _set: { hasRead: $hasRead, lastViewedAt: "now()" }
  ) {
    hasRead
    hasMarked
    lastViewedAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserClaimStatusByPkHasRead', 'query'),
    updateUserClaimStatusByPkHasMarked: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserClaimStatusByPkHasMarked($userId: uuid!, $claimId: uuid!, $hasMarked: Boolean!) {
  updateUserClaimStatusByPk(
    pkColumns: { claimId: $claimId, userId: $userId }
    _set: { hasMarked: $hasMarked }
  ) {
    hasMarked
    hasRead
    lastViewedAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserClaimStatusByPkHasMarked', 'query')
  }
}