import type { EventFieldsFragment } from "#gql";
import { nanoid } from "nanoid";
import { defineStore } from "pinia";

export const ACTIVITY_FILTERS = ["all", "submissions", "factchecks"] as const;
export type ActivityFilter = (typeof ACTIVITY_FILTERS)[number];

export type ActivityStoreState = {
  events: EventFieldsFragment[];
  isLoadingInitialEvents: boolean;
  isLoadingNextPage: boolean;
  hasMoreEvents: boolean;
  error: string | null;
  pageSize: number;
  lastRequestedId: string | null;
  filter: ActivityFilter;
};

function concatEvents(
  events: EventFieldsFragment[] = [],
  newEvents: EventFieldsFragment[] = [],
  removeLastNewEvent: boolean = false
) {
  const index = events.findIndex((event) => event.id === newEvents[0].id);
  if (index !== -1) {
    return events.slice(index);
  }
  return events.concat(removeLastNewEvent ? newEvents.slice(0, -1) : newEvents);
}

const filterMap: Record<ActivityFilter, (event: EventFieldsFragment) => boolean> = {
  all: (event: EventFieldsFragment) => event.claim !== null,
  submissions: (event: EventFieldsFragment) =>
    !!(event.claim && SUBMISSION_STATUSES.includes(event.claim?.status)),
  factchecks: (event: EventFieldsFragment) =>
    !!(event.claim && FACTCHECK_STATUSES.includes(event.claim?.status))
};

const QueryFilterClaimStatusMap: Record<
  ActivityFilter,
  (pageSize: number, lastEventId?: string) => Promise<{ data: EventFieldsFragment[] }>
> = {
  all: (pageSize, lastEventId) =>
    GqlGetClaimFilteredEventsPage({
      pageSize,
      idComparison: lastEventId ? { _lt: lastEventId } : {},
      claimComparison: {}
    }),
  submissions: (pageSize, lastEventId) =>
    GqlGetClaimFilteredEventsPage({
      pageSize,
      idComparison: lastEventId ? { _lt: lastEventId } : {},
      claimComparison: { status: { _in: SUBMISSION_STATUSES } }
    }),
  factchecks: (pageSize, lastEventId) =>
    GqlGetClaimFilteredEventsPage({
      pageSize,
      idComparison: lastEventId ? { _lt: lastEventId } : {},
      claimComparison: { status: { _in: FACTCHECK_STATUSES } }
    })
};

export const useActivityStore = defineStore("activity", {
  state: () =>
    ({
      events: [],
      isLoadingInitialEvents: false,
      isLoadingNextPage: false,
      hasMoreEvents: true,
      error: null,
      pageSize: 10,
      lastRequestedId: null,
      filter: "all"
    }) as ActivityStoreState,
  actions: {
    addEvent(event: EventFieldsFragment) {
      // during loding the new content
      if (filterMap[this.filter](event)) {
        this.events.unshift(event);
      }
    },
    async setFilter(filter: ActivityFilter) {
      this.filter = filter;

      return this.initialLoadEvents();
    },

    async initialLoadEvents() {
      try {
        this.isLoadingInitialEvents = true;
        this.hasMoreEvents = false;
        const requestId = nanoid();
        this.lastRequestedId = requestId;
        this.events = [];
        const { data: eventsFirstPage } = await QueryFilterClaimStatusMap[this.filter](this.pageSize + 1);
        if (this.lastRequestedId !== requestId) {
          // a new request was made, so we don't need to process the response
          return;
        }
        // check if it was possible to load more events
        this.hasMoreEvents = eventsFirstPage.length > this.pageSize;
        this.events = concatEvents(this.events, eventsFirstPage, this.hasMoreEvents);
      } catch (error) {
        this.error = error + "";
        console.error(error);
      } finally {
        this.isLoadingInitialEvents = false;
      }
    },
    async loadNextPage() {
      try {
        this.isLoadingNextPage = true;
        this.hasMoreEvents = false;
        const requestId = nanoid();
        this.lastRequestedId = requestId;
        const lastEventId = this.events[this.events.length - 1]?.id;

        const { data: eventsNextPage } = await QueryFilterClaimStatusMap[this.filter](
          this.pageSize + 1,
          lastEventId
        );

        if (this.lastRequestedId !== requestId) {
          // a new request was made, so we don't need to process the response
          return;
        }
        // check if it was possible to load more events
        this.hasMoreEvents = eventsNextPage.length > this.pageSize;

        this.events = concatEvents(this.events, eventsNextPage, this.hasMoreEvents);
      } catch (error) {
        this.error = error + "";
        console.error(error);
      } finally {
        this.isLoadingNextPage = false;
      }
    }
  },
  getters: {
    // event getters
  }
});
