import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/src/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_yjz_FxLIxadqwp6_Sovn2pVgsO0iA7tScVL_bgYsjTE from "/src/node_modules/vue-sonner/lib/nuxt/runtime/plugin.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/src/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_6ZhjavnMxFfYFG7tsyjoKHpgVIiZCdAzIuNWHwsY8yk from "/src/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps from "/src/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ from "/src/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import _00_autoanimate_LckwKjCnEyaNt5j2Q8I9_huPvlx27AMfK2bQtEAcIhY from "/src/src/plugins/00.autoanimate.ts";
import _00_logging_client_rlDDj__8Bw2FgXE14rz5u64ITXBIISbK4wzpLb2BdKk from "/src/src/plugins/00.logging.client.ts";
import _00_vue_query_PfpFRUenVMpxvp28aIble_WrtkWnerPbEKTPdtA2TLU from "/src/src/plugins/00.vue-query.ts";
import _01_ory_sdk_8dnzWr0U0_Mb1gA03SzxjADJnruli8LlxwKIZqOVYcw from "/src/src/plugins/01.ory-sdk.ts";
import _02_chat_client_I5NUWaiOL4S5d7KGvK7gVN1DHfz0o6DfTSt9OdOPuqw from "/src/src/plugins/02.chat.client.ts";
import index_client_QkW5lMLetI1ncnShbnh5XfMsQLvJJ1Hu5BCQzjBo8Z0 from "/src/src/plugins/activityStream/index.client.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_yjz_FxLIxadqwp6_Sovn2pVgsO0iA7tScVL_bgYsjTE,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_6ZhjavnMxFfYFG7tsyjoKHpgVIiZCdAzIuNWHwsY8yk,
  plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ,
  _00_autoanimate_LckwKjCnEyaNt5j2Q8I9_huPvlx27AMfK2bQtEAcIhY,
  _00_logging_client_rlDDj__8Bw2FgXE14rz5u64ITXBIISbK4wzpLb2BdKk,
  _00_vue_query_PfpFRUenVMpxvp28aIble_WrtkWnerPbEKTPdtA2TLU,
  _01_ory_sdk_8dnzWr0U0_Mb1gA03SzxjADJnruli8LlxwKIZqOVYcw,
  _02_chat_client_I5NUWaiOL4S5d7KGvK7gVN1DHfz0o6DfTSt9OdOPuqw,
  index_client_QkW5lMLetI1ncnShbnh5XfMsQLvJJ1Hu5BCQzjBo8Z0,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]