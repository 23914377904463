import type { SubscribeEventsSubscription } from "#gql/default";

type Event = SubscribeEventsSubscription["eventStream"][number];

export type DataChanges = {
  claims: Set<string>;
  users: Set<string>;
  avatars: Set<string>;
  profile: boolean;
  claimLists: boolean;
  userLists: boolean;
};

// Handler functions

function handleOrigin(event: Event, acc: DataChanges) {
  acc.claims.add(event.claimId);
  if (event.action === "DELETE") {
    // origin could contain first image of claim and claim Order changes
    acc.claimLists = true;
    return acc;
  }
  if (event.content.file_id) {
    acc.claimLists = true;
  }
}
function handleClaimCategory(event: Event, acc: DataChanges) {
  acc.claims.add(event.claimId);
  acc.claimLists = true;
}

const relevantClaimEventClaimListKeys = new Set([
  "synopsis",
  "processId",
  "status",
  "internal",
  "publishingUrl",
  "rating_label_name"
]);
function handleClaim(event: Event, acc: DataChanges) {
  if (event.action === "DELETE") {
    acc.claimLists = true;
    return;
  }

  acc.claims.add(event.claimId);

  if (Object.keys(event.content).some((key) => relevantClaimEventClaimListKeys.has(key))) {
    acc.claimLists = true;
  }
}

function handleFact(event: Event, acc: DataChanges) {
  acc.claims.add(event.claimId);
}

function handleSource(event: Event, acc: DataChanges) {
  acc.claims.add(event.claimId);
}

function handleUser(event: Event, acc: DataChanges, userId?: string) {
  acc.users.add(event.entryId);
  console.warn("eventStream: User", event.entryId, userId);
  if (event.entryId === userId) {
    acc.profile = true;
  }

  if (event.action === "DELETE") {
    acc.userLists = true;
    return;
  }

  if (Object.keys(event.content).length === 0) {
    acc.userLists = true;
  } else {
    acc.users.add(event.entryId);
    if (event.content.profile_image) {
      acc.avatars.add(event.entryId);
    }
  }
}

function unknownHandler(_event: Event, _acc: DataChanges) {
  // No operation
  console.warn("eventStream: Unknown event", _event);
}
const tableHandlers: Record<string, (event: Event, acc: DataChanges, userId?: string) => void> = {
  [TABLES.claim]: handleClaim,
  [TABLES.claim_category]: handleClaimCategory,
  [TABLES.origin]: handleOrigin,
  [TABLES.fact]: handleFact,
  [TABLES.source]: handleSource,
  [TABLES.user]: handleUser
};

export function aggregateEvents(data: SubscribeEventsSubscription, userId?: string) {
  // Define handler functions for different table types

  return data.eventStream.reduce<DataChanges>(
    (acc, event) => {
      console.debug("eventStream: Event received", event);

      // Find handler for this table or use no-op function
      const handler = tableHandlers[event.tableName] || unknownHandler;
      handler(event, acc, userId);

      return acc;
    },
    {
      claims: new Set(),
      users: new Set(),
      avatars: new Set(),
      profile: false,
      claimLists: false,
      userLists: false
    }
  );
}
