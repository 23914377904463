
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93fAMtY96oiK1c5iYpawZqo8efeO_455gN_45YOJHdFJ6n7W8Meta } from "/src/src/pages/activity/claims/[id].vue?macro=true";
import { default as indexIXTA_45zpZg6YNpL8VcChiicJi6ryNQp5U6uT_45mabtMYUMeta } from "/src/src/pages/activity/index.vue?macro=true";
import { default as _91id_93cE46Gao9eBdCJYhbs3bzD4rtl_45_450GeOKwKqQK26fKJUMeta } from "/src/src/pages/activity/submissions/[id].vue?macro=true";
import { default as indexO5_WmVBv6sFv5DxPuwVWbgDCnSFf1l9RDahtvVJf_eEMeta } from "/src/src/pages/admin/settings/index.vue?macro=true";
import { default as _91id_93LEbJuQggsWpit1y8i6HFvOBNzRgSCzrd3S9TD4jsh_kMeta } from "/src/src/pages/admin/users/[id].vue?macro=true";
import { default as indexDvmgZUgVori76Jhrzn_45iULvq7UvzZWTpuHx0_45j5mYzkMeta } from "/src/src/pages/admin/users/index.vue?macro=true";
import { default as authentication_45settings7Lk_45h_avziZvDg3TTxD7ATmIftxd_45CtBYwatyXF4RygMeta } from "/src/src/pages/authentication-settings.vue?macro=true";
import { default as _91id_93PSc4jzJj5RVrZvgC8zhvvQFU9ChLNg36Gin86G0iwPQMeta } from "/src/src/pages/claims/[filter]/[id].vue?macro=true";
import { default as indexW9hDZJWOMvI3oL9i8vnOG74Mwc8nUC0KHgoA_ZpsaUUMeta } from "/src/src/pages/claims/[filter]/index.vue?macro=true";
import { default as _91id_932dhGHzVJ9NkYRv7kDGxV_45icfFRlEg6x5kyxGhj5Dp2cMeta } from "/src/src/pages/forum/channels/[id].vue?macro=true";
import { default as _91id_93p9uUoR2XqOG5ep2TLdW_TiJpCJZa1wDlEKyGrvHmcIEMeta } from "/src/src/pages/handbook/[id].vue?macro=true";
import { default as indexO0JAxV6muvgSdDj9pPRhQLvxgaArXWhorAX_YqYbDeoMeta } from "/src/src/pages/handbook/index.vue?macro=true";
import { default as indexjc0N8OCoFV4fMpKekPIErsn_45N_VF3KIvHN31XAQ5P6cMeta } from "/src/src/pages/index.vue?macro=true";
import { default as loginTSlLqAEaEXvyT5KN2Tjzos4oxId95WAynQjAPMCTvdIMeta } from "/src/src/pages/login.vue?macro=true";
import { default as index_Q5DazXc1yD7s_CLOrVHj4r73_RNa_6Br0XxZqlD6BIMeta } from "/src/src/pages/profile/index.vue?macro=true";
import { default as recovery1BPMDLHfWc_4500sm_45_45RuRh_456FN_45C8DNky_DxUEYvqVw8Meta } from "/src/src/pages/recovery.vue?macro=true";
import { default as registrationIdMNDhC5M2Vu9_45H_45y7NKKnbikkM4EdKwEyUKPArUol4Meta } from "/src/src/pages/registration.vue?macro=true";
import { default as _91id_93_kUU2GKNrvSqUOy90uoYx2FqsXghiAUWmiHZfDbP_45JoMeta } from "/src/src/pages/submissions/[filter]/[id].vue?macro=true";
import { default as indexRexaGsWS28fuQ5_csTl9Rk0JZtl8YY3m8j2Z99ZidmcMeta } from "/src/src/pages/submissions/[filter]/index.vue?macro=true";
import { default as verificationu4PwI8VQEPNGiEcmTKvq_TNB0xrXPJZWv6dULkPQsrMMeta } from "/src/src/pages/verification.vue?macro=true";
import { default as component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlgMeta } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlg } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "activity-claim",
    path: "/activity/claims/:id()",
    meta: _91id_93fAMtY96oiK1c5iYpawZqo8efeO_455gN_45YOJHdFJ6n7W8Meta || {},
    component: () => import("/src/src/pages/activity/claims/[id].vue")
  },
  {
    name: "activity",
    path: "/activity",
    meta: indexIXTA_45zpZg6YNpL8VcChiicJi6ryNQp5U6uT_45mabtMYUMeta || {},
    component: () => import("/src/src/pages/activity/index.vue")
  },
  {
    name: "activity-submission",
    path: "/activity/submissions/:id()",
    meta: _91id_93cE46Gao9eBdCJYhbs3bzD4rtl_45_450GeOKwKqQK26fKJUMeta || {},
    component: () => import("/src/src/pages/activity/submissions/[id].vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: indexO5_WmVBv6sFv5DxPuwVWbgDCnSFf1l9RDahtvVJf_eEMeta || {},
    component: () => import("/src/src/pages/admin/settings/index.vue")
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    meta: _91id_93LEbJuQggsWpit1y8i6HFvOBNzRgSCzrd3S9TD4jsh_kMeta || {},
    component: () => import("/src/src/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexDvmgZUgVori76Jhrzn_45iULvq7UvzZWTpuHx0_45j5mYzkMeta || {},
    component: () => import("/src/src/pages/admin/users/index.vue")
  },
  {
    name: "authentication-settings",
    path: "/authentication-settings",
    meta: authentication_45settings7Lk_45h_avziZvDg3TTxD7ATmIftxd_45CtBYwatyXF4RygMeta || {},
    component: () => import("/src/src/pages/authentication-settings.vue")
  },
  {
    name: "claim",
    path: "/claims/:filter()/:id()",
    meta: _91id_93PSc4jzJj5RVrZvgC8zhvvQFU9ChLNg36Gin86G0iwPQMeta || {},
    component: () => import("/src/src/pages/claims/[filter]/[id].vue")
  },
  {
    name: "claims",
    path: "/claims/:filter()",
    meta: indexW9hDZJWOMvI3oL9i8vnOG74Mwc8nUC0KHgoA_ZpsaUUMeta || {},
    component: () => import("/src/src/pages/claims/[filter]/index.vue")
  },
  {
    name: "forum",
    path: "/forum/channels/:id()",
    meta: _91id_932dhGHzVJ9NkYRv7kDGxV_45icfFRlEg6x5kyxGhj5Dp2cMeta || {},
    component: () => import("/src/src/pages/forum/channels/[id].vue")
  },
  {
    name: "handbook-page",
    path: "/handbook/:id()",
    meta: _91id_93p9uUoR2XqOG5ep2TLdW_TiJpCJZa1wDlEKyGrvHmcIEMeta || {},
    component: () => import("/src/src/pages/handbook/[id].vue")
  },
  {
    name: "handbook",
    path: "/handbook",
    meta: indexO0JAxV6muvgSdDj9pPRhQLvxgaArXWhorAX_YqYbDeoMeta || {},
    component: () => import("/src/src/pages/handbook/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjc0N8OCoFV4fMpKekPIErsn_45N_VF3KIvHN31XAQ5P6cMeta || {},
    component: () => import("/src/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTSlLqAEaEXvyT5KN2Tjzos4oxId95WAynQjAPMCTvdIMeta || {},
    component: () => import("/src/src/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index_Q5DazXc1yD7s_CLOrVHj4r73_RNa_6Br0XxZqlD6BIMeta || {},
    component: () => import("/src/src/pages/profile/index.vue")
  },
  {
    name: "recovery",
    path: "/recovery",
    meta: recovery1BPMDLHfWc_4500sm_45_45RuRh_456FN_45C8DNky_DxUEYvqVw8Meta || {},
    component: () => import("/src/src/pages/recovery.vue")
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationIdMNDhC5M2Vu9_45H_45y7NKKnbikkM4EdKwEyUKPArUol4Meta || {},
    component: () => import("/src/src/pages/registration.vue")
  },
  {
    name: "submission",
    path: "/submissions/:filter()/:id()",
    meta: _91id_93_kUU2GKNrvSqUOy90uoYx2FqsXghiAUWmiHZfDbP_45JoMeta || {},
    component: () => import("/src/src/pages/submissions/[filter]/[id].vue")
  },
  {
    name: "submissions",
    path: "/submissions/:filter()",
    meta: indexRexaGsWS28fuQ5_csTl9Rk0JZtl8YY3m8j2Z99ZidmcMeta || {},
    component: () => import("/src/src/pages/submissions/[filter]/index.vue")
  },
  {
    name: "verification",
    path: "/verification",
    meta: verificationu4PwI8VQEPNGiEcmTKvq_TNB0xrXPJZWv6dULkPQsrMMeta || {},
    component: () => import("/src/src/pages/verification.vue")
  },
  {
    name: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlgMeta?.name,
    path: "/submissions",
    component: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlg
  },
  {
    name: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlgMeta?.name,
    path: "/claims",
    component: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlg
  },
  {
    name: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlgMeta?.name,
    path: "/users",
    component: component_45stub6rf8OGKDOmXgRQbeH7L4Y3ulXNJgTf1t_Zpz0MjpAlg
  }
]