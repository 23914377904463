// plugins/graphql-subscription.ts
import { defineNuxtPlugin, useNuxtApp } from "#app";
import type { SubscribeEventsSubscription, SubscribeEventsSubscriptionVariables } from "#gql/default";

import { useQueryClient } from "@tanstack/vue-query";

import { aggregateEvents } from "./eventHandler";
import SubscribeEventsDocument from "@/queries/events/streamEvents.gql";

export default defineNuxtPlugin(() => {
  const { $logger } = useNuxtApp();
  const { subscribe } = useGqlSubscribtion();
  const queryClient = useQueryClient();

  const { userId, loggedIn, userRole } = useSessionInfo();
  const unsubscribe = ref<() => void>(() => {});
  const store = useActivityStore();
  watch(loggedIn, (loggedIn) => {
    if (loggedIn) {
      if (userRole.value !== UserRole.Aspirant) {
        onUserLogin();
      } else {
        userLogout();
      }
    }
  });

  function onError(error: unknown) {
    $logger.error("eventStream: Error received", error);
  }

  async function onUserLogin() {
    $logger.info("eventStream: User logged in");
    const result = await GqlGetLastEventId();
    const lastEventId = result.event[0].id;
    store.initialLoadEvents();

    const { unsubscribe: localUnsubscribe } = subscribe<
      SubscribeEventsSubscription,
      SubscribeEventsSubscriptionVariables
    >(
      SubscribeEventsDocument,
      { id: lastEventId, batchSize: 1000 },
      {
        onError,
        onNext: (data) => {
          console.debug("eventStream: Received data", data);
          for (const event of data.eventStream) {
            store.addEvent(event);
          }
          const invalidationMap = aggregateEvents(data, userId.value);
          // invalidate lists
          if (invalidationMap.claimLists) {
            $logger.debug("eventStream: Invalidating claims");
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims], exact: false });
          }
          if (invalidationMap.userLists) {
            $logger.debug("eventStream: Invalidating users");
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.usersPaginated], exact: false });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.users], exact: false });
          }
          if (invalidationMap.profile) {
            $logger.debug("eventStream: Invalidating profile");
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userAccountView], exact: true });
          }
          // invalidate single claims and users
          if (invalidationMap.claims.size > 0) {
            for (const claimId of invalidationMap.claims) {
              $logger.debug("eventStream: Invalidating claim", claimId);
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claim, claimId], exact: true });
            }
          }
          if (invalidationMap.users.size > 0) {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.usersPaginated] });
            for (const userId of invalidationMap.users) {
              $logger.debug("eventStream: Invalidating user", userId);
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.user, userId], exact: true });
            }
          }

          if (invalidationMap.avatars.size > 0) {
            for (const avatarId of invalidationMap.avatars) {
              $logger.debug("eventStream: todo: Invalidating avatar", avatarId);
            }
          }
        }
      }
    );
    unsubscribe.value = localUnsubscribe;
  }

  function userLogout() {
    $logger.info("eventStream: User logged out");
    unsubscribe.value();
  }
});
