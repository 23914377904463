export default function useExitAlert() {
  const claimStore = useClaimStore();

  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (claimStore.unsavedChanges.size > 0) {
      event.preventDefault();
      event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
    }
  }

  onMounted(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  });

  onUnmounted(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });
}
